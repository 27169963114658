
    var doc = {"kind":"Document","definitions":[{"kind":"EnumTypeDefinition","name":{"kind":"Name","value":"ThemeEnum"},"directives":[],"values":[{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"LIGHT"},"directives":[]},{"kind":"EnumValueDefinition","name":{"kind":"Name","value":"DARK"},"directives":[]}]},{"kind":"ObjectTypeDefinition","name":{"kind":"Name","value":"TermHistory"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"term"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"createdAt"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},"directives":[]}]},{"kind":"ObjectTypeExtension","name":{"kind":"Name","value":"Query"},"interfaces":[],"directives":[],"fields":[{"kind":"FieldDefinition","name":{"kind":"Name","value":"theme"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ThemeEnum"}}},"directives":[]},{"kind":"FieldDefinition","name":{"kind":"Name","value":"terms"},"arguments":[],"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TermHistory"}}}}},"directives":[]}]}],"loc":{"start":0,"end":161}};
    doc.loc.source = {"body":"enum ThemeEnum {\n  LIGHT\n  DARK\n}\n\ntype TermHistory {\n  term: String!\n  createdAt: String!\n}\n\nextend type Query {\n  theme: ThemeEnum!\n  terms: [TermHistory!]!\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
